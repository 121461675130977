var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.article
    ? _c("div", { staticClass: "response padding" }, [
        _c("h2", { staticClass: "text-center" }, [
          _vm._v(_vm._s(_vm.article.title))
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "text-df",
          domProps: { innerHTML: _vm._s(_vm.article.content) }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }