var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      _vm._l(_vm.goods.images, function(item) {
        return _c("img", { staticClass: "response", attrs: { src: item } })
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "van-button",
          {
            staticClass: "position-fixed response border-radius-0",
            staticStyle: { bottom: "0" },
            attrs: { type: "info" },
            nativeOn: {
              click: function($event) {
                return _vm.insureClick($event)
              }
            }
          },
          [_vm._v("立即投保")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "van-dialog",
          {
            attrs: {
              title: "投保人信息",
              showCancelButton: "",
              "before-close": _vm.confirm,
              "confirm-button-color": "#1989fa"
            },
            model: {
              value: _vm.dialogVisable,
              callback: function($$v) {
                _vm.dialogVisable = $$v
              },
              expression: "dialogVisable"
            }
          },
          [
            _c(
              "div",
              { staticClass: "padding" },
              [
                _c("van-field", {
                  ref: "policyholder_name",
                  attrs: {
                    placeholder: "请输入投保人姓名",
                    autofocus: "",
                    required: true
                  },
                  model: {
                    value: _vm.form.policyholder_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "policyholder_name", $$v)
                    },
                    expression: "form.policyholder_name"
                  }
                }),
                _vm._v(" "),
                _c("van-field", {
                  attrs: { placeholder: "请输入投保人身份证", required: true },
                  model: {
                    value: _vm.form.policyholder_identityno,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "policyholder_identityno", $$v)
                    },
                    expression: "form.policyholder_identityno"
                  }
                })
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }